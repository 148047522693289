import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/HomePage.vue';
import SEOTool from '@/SEOTool.vue';
import Coze from '@/Coze.vue';
import Master from '@/Master.vue';
import './../firebaseConfig';

const loadCSS = (href) => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  link.id = `dynamic-css-${href}`; // 增加ID以便識別和移除
  document.head.appendChild(link);
};

const removeCSS = (href) => {
  const link = document.querySelector(`link[href="${href}"]`);
  if (link) {
    link.remove();
  }
};

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    beforeEnter: (to, from, next) => {
      // 切換到首頁時移除其他頁面的樣式
      removeCSS('/css/master.css');
      next(); // 確保正確跳轉
    },
  },
  {
    path: '/seo',
    name: 'SEOTool',
    component: SEOTool,
    beforeEnter: (to, from, next) => {
      removeCSS('/css/master.css');
      next();
    },
  },
  {
    path: '/coze',
    name: 'Coze',
    component: Coze,
    beforeEnter: (to, from, next) => {
      removeCSS('/css/master.css');
      next();
    },
  },
  {
    path: '/master',
    name: 'Master',
    component: Master,
    beforeEnter: (to, from, next) => {
      // 移除其他路徑的樣式，載入 Master 的樣式
      removeCSS('/css/main.css'); // 確保其他樣式移除
      loadCSS('/css/master.css');
      next(); // 確保跳轉到 Master 頁面
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
